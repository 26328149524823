declare global {
  interface Window {
    CDD: {
      current_user?: {
        email?: string;
        firstName?: string;
        lastName?: string;
        disabled?: boolean;
        id?: number;
        username?: string;
      }
    }
  }
}

export const getCurrentUser = () => { return window.CDD?.current_user ?? {}; };
